@import "~@ng-select/ng-select/themes/material.theme.css";
/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@media (min-width: 992px) {
  main.container {
      max-width: 1440px;
  }
}
header,nav#site-specific-nav,nav#site-specific-nav.centered-nav,main,footer {
  max-width: 1440px;
}


/* Custom Styles by Sree Natarajan */
table, mat-form-field {
  width: 100%;
}

.wpd-loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wpd-cluster>div {
  font-weight: bold;
  padding-top: 8px;
}

.mapInfo {
  background-color: #fff;
  border: 2px solid #00B3DF;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0,0,0,.3);
  margin-bottom: 22px;
  color: rgb(25,25,25);
  font-family: Roboto,Arial,sans-serif;
  font-size: 16px;
  margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.padding-top-24 {
  padding-top: 24px;
}

table.table-MOQ tr th, table.table-MOQ tr td {
  padding: 2px;
}
.table-MOQ textarea
{
  width: 100%;
  padding: 2px;
  min-height: 50px;
}

.excel-download.mdc-fab, .excel-upload.mdc-fab, .csv-download.mdc-fab {  
  height: 30px;
  width: 30px;
  position: absolute;
  top: 10px;
  right: 70px;
  background-color: #1d6f42;
}
.excel-upload.mdc-fab {  
  right: 20px;
}
.csv-download.mdc-fab {  
  right: 120px;
}
@media (max-width: 767.98px)
{
  table tr th, table tr td {
      display: table-cell;
  }
}

.html-tooltip .mdc-tooltip__surface {   
  max-width: unset !important;
  min-width: 500px;
}